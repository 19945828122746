import axios from '@/axios-client.js';
import store from '@/store';

const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;

const featureTypeAPI = {
  async deleteFeatureType(featureType_slug) {
    const response = await axios.delete(
      `${baseUrl}feature-types/${featureType_slug}`
    );
    if (
      response.status === 204
    ) {
      return 'success';
    } else {
      return null;
    }
  },

};

export default featureTypeAPI;
