var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"ui header"},[_vm._v(" Types de signalements ")]),_c('div',{staticClass:"ui middle aligned divided list",attrs:{"id":"feature_type-list"}},[_c('div',{staticClass:"ui inverted dimmer",class:{ active: _vm.loading }},[_c('div',{staticClass:"ui text loader"},[_vm._v(" Récupération des types de signalements en cours... ")])]),_c('div',{staticClass:"ui inverted dimmer",class:{ active: _vm.importing }},[_c('div',{staticClass:"ui text loader"},[_vm._v(" Traitement du fichier en cours ... ")])]),_vm._l((_vm.feature_types),function(type,index){return _c('div',{key:type.title + '-' + index,staticClass:"item",attrs:{"id":type.title}},[_c('div',{staticClass:"feature-type-container"},[_c('FeatureTypeLink',{attrs:{"feature-type":type}}),_c('div',{staticClass:"middle aligned content"},[(
              _vm.project && _vm.permissions && _vm.permissions.can_create_feature && !type.geom_type.includes('multi')
            )?_c('router-link',{staticClass:"ui compact small icon right floated button button-hover-green tiny-margin",attrs:{"to":{
              name: 'ajouter-signalement',
              params: { slug_type_signal: type.slug },
            },"data-tooltip":"Ajouter un signalement","data-position":"top right","data-variation":"mini"}},[_c('i',{staticClass:"ui plus icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),(
              _vm.project &&
                _vm.permissions &&
                _vm.permissions.can_create_feature_type &&
                _vm.isOnline
            )?_c('router-link',{staticClass:"ui compact small icon right floated button button-hover-green tiny-margin",attrs:{"to":{
              name: 'dupliquer-type-signalement',
              params: { slug_type_signal: type.slug },
            },"data-tooltip":"Dupliquer un type de signalement","data-position":"top right","data-variation":"mini"}},[_c('i',{staticClass:"inverted grey copy alternate icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.isImporting(type))?_c('div',{staticClass:"import-message"},[_c('i',{staticClass:"info circle icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Import en cours ")]):_c('div',[(_vm.isProjectAdmin && _vm.isOnline)?_c('a',{staticClass:"ui compact small icon right floated button button-hover-red tiny-margin",attrs:{"data-tooltip":"Supprimer le type de signalement","data-position":"top center","data-variation":"mini"},on:{"click":function($event){return _vm.toggleDeleteFeatureType(type)}}},[_c('i',{staticClass:"inverted grey trash alternate icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),(
                _vm.project &&
                  _vm.permissions &&
                  _vm.permissions.can_create_feature_type &&
                  _vm.isOnline
              )?_c('router-link',{staticClass:"ui compact small icon right floated button button-hover-orange tiny-margin",attrs:{"to":{
                name: 'editer-symbologie-signalement',
                params: { slug_type_signal: type.slug },
              },"data-tooltip":"Éditer la symbologie du type de signalement","data-position":"top center","data-variation":"mini"}},[_c('i',{staticClass:"inverted grey paint brush alternate icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),(
                _vm.project &&
                  type.is_editable &&
                  _vm.permissions &&
                  _vm.permissions.can_create_feature_type &&
                  _vm.isOnline
              )?_c('router-link',{staticClass:"ui compact small icon right floated button button-hover-orange tiny-margin",attrs:{"to":{
                name: 'editer-type-signalement',
                params: { slug_type_signal: type.slug },
              },"data-tooltip":"Éditer le type de signalement","data-position":"top center","data-variation":"mini"}},[_c('i',{staticClass:"inverted grey pencil alternate icon",attrs:{"aria-hidden":"true"}})]):_vm._e()],1)],1)],1)])}),(_vm.feature_types.length === 0)?_c('div',[_c('em',[_vm._v(" Le projet ne contient pas encore de type de signalements. ")])]):_vm._e()],2),_c('div',{staticClass:"nouveau-type-signalement-container"},[_c('div',{staticClass:"ui small button circular compact floated right icon teal",attrs:{"data-tooltip":"Consulter la documentation","data-position":"bottom right","data-variation":"mini"}},[_c('i',{staticClass:"question icon",on:{"click":_vm.goToDocumentation}})]),_c('div',{attrs:{"id":"nouveau-type-signalement"}},[(
          _vm.permissions &&
            _vm.permissions.can_update_project &&
            _vm.isOnline
        )?_c('router-link',{staticClass:"ui compact basic button",attrs:{"to":{
          name: 'ajouter-type-signalement',
          params: { slug: _vm.slug },
        }}},[_c('i',{staticClass:"ui plus icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Créer un nouveau type de signalement ")]):_vm._e()],1),_c('div',{staticClass:"nouveau-type-signalement"},[(
          _vm.permissions &&
            _vm.permissions.can_update_project &&
            _vm.isOnline
        )?_c('div',{staticClass:"ui compact basic button button-align-left"},[_c('i',{staticClass:"ui plus icon",attrs:{"aria-hidden":"true"}}),_vm._m(0),_c('input',{staticStyle:{"display":"none"},attrs:{"id":"json_file","type":"file","accept":"application/json, .json, .geojson","name":"json_file"},on:{"change":_vm.onGeoJSONFileChange}})]):_vm._e()]),_c('div',{staticClass:"nouveau-type-signalement"},[(
          _vm.permissions &&
            _vm.permissions.can_update_project &&
            _vm.isOnline
        )?_c('div',{staticClass:"ui compact basic button button-align-left"},[_c('i',{staticClass:"ui plus icon",attrs:{"aria-hidden":"true"}}),_vm._m(1),_c('input',{staticStyle:{"display":"none"},attrs:{"id":"csv_file","type":"file","accept":"application/csv, .csv","name":"csv_file"},on:{"change":_vm.onCSVFileChange}})]):_vm._e()]),_c('div',{staticClass:"nouveau-type-signalement"},[(
          _vm.IDGO &&
            _vm.permissions &&
            _vm.permissions.can_update_project &&
            _vm.isOnline
        )?_c('router-link',{staticClass:"ui compact basic button button-align-left",attrs:{"to":{
          name: 'catalog-import',
          params: {
            slug: _vm.slug,
            feature_type_slug: 'create'
          },
        }}},[_c('i',{staticClass:"ui plus icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Créer un nouveau type de signalement à partir du catalogue "+_vm._s(_vm.CATALOG_NAME || 'IDGO')+" ")]):_vm._e()],1)]),(_vm.geojsonFileToImport.size > 0)?_c('div',{attrs:{"id":"button-import"}},[_c('button',{staticClass:"ui fluid teal icon button",attrs:{"disabled":_vm.geojsonFileToImport.size === 0},on:{"click":_vm.toNewGeojsonFeatureType}},[_c('i',{staticClass:"upload icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Lancer l'import avec le fichier "+_vm._s(_vm.geojsonFileToImport.name)+" ")])]):_vm._e(),(_vm.csvFileToImport.size > 0 && !_vm.csvError)?_c('div',{attrs:{"id":"button-import"}},[_c('button',{staticClass:"ui fluid teal icon button",attrs:{"disabled":_vm.csvFileToImport.size === 0},on:{"click":_vm.toNewCsvFeatureType}},[_c('i',{staticClass:"upload icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Lancer l'import avec le fichier "+_vm._s(_vm.csvFileToImport.name)+" ")])]):_vm._e(),(_vm.csvError)?_c('div',{staticClass:"ui negative message"},[_c('i',{staticClass:"close icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){_vm.csvError = null; _vm.csvFileToImport = { name: '', size: 0 }}}}),_vm._v(" "+_vm._s(_vm.csvError)+" ")]):_vm._e(),_c('div',{staticClass:"ui dimmer inverted",class:_vm.isFileSizeModalOpen ? 'active' : ''},[_c('div',{staticClass:"ui modal tiny",class:_vm.isFileSizeModalOpen ? 'active' : '',staticStyle:{"top":"20%"}},[_c('div',{staticClass:"header"},[_vm._v(" Fichier trop grand! ")]),_c('div',{staticClass:"content"},[_c('p',[_vm._v(" Impossible de créer un type de signalement à partir d'un fichier de plus de 100Mo (celui importé fait "+_vm._s(_vm.geojsonFileSize > 0 ? _vm.geojsonFileSize : _vm.csvFileSize)+" Mo). ")])]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"ui button teal",on:{"click":_vm.closeFileSizeModal}},[_vm._v(" Fermer ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"ui",attrs:{"for":"json_file"}},[_c('span',{staticClass:"label"},[_vm._v("Créer un nouveau type de signalement à partir d'un GeoJSON")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"ui",attrs:{"for":"csv_file"}},[_c('span',{staticClass:"label"},[_vm._v(" Créer un nouveau type de signalement à partir d'un CSV ")])])
}]

export { render, staticRenderFns }